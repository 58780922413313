.products {
    max-width: 100%;
    background: #454545;
}

._productBanner_ {
    height: 300px;
    width: 100%;
    background: #181818;
}

._productCover_{
    padding: 0px 140px 140px 140px;
    background: #181818;
}
._productsWrap_{
    display: flex;
    justify-content: space-between;
}
._headingWrap_{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
._headingWrap_ a{
    font-size: 18px;
    color: #b9b9b9;
    font-family: sans-serif;

}
._bookwrapCards_{
    display: flex;
    justify-content: space-between;
}
._itemImage_{
    width: 390px;
    height: 265px;
}
._itemCard_{
    border: 1px solid #3d3d3d;
    width: 390px;
    text-align: center;
    /* height: 390px; */
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background:unset;
    margin-bottom: 30px;
}
.__productImage__{
    width: 390px;
    height: 265px;
}
.__bookImage__{
    width: 300px;
    height: 450px;
}
._totalProducts_{
    margin-bottom: 50px;
    padding-top: 85px;
}
._heading_{
    font-size: 30px;
  font-weight: 500;
  color: #fff;
  font-family: sans-serif;
}
._productHeading_{
    font-size: 34px;
    margin-bottom: 50px;
    color: #ffff;
    font-family: sans-serif;
  font-weight: 500;
}
.product-image {
    width: 70%;
    border-radius: 50%;
    background: linear-gradient(45deg, transparent, transparent 40%, #e42c0b);
    transition: transform .3s, -webkit-transform .3s;
    animation: circle 2s linear infinite;
}
._productImage_{
    width: 50px;
    height: 50px;
}
._item_{
    width: 131px;
    /* margin-right: 57px; */
    align-items: center;
    display: flex;
    flex-direction: column;
}
._wrapCards_{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}
.__CardTitle__{
    font-size: 22px;
    color: #b9b9b9;
    overflow: auto;
    text-overflow: ellipsis;
    font-family: sans-serif;
    line-height: 1.3;
    white-space: nowrap;
    width:inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch !important;
}
._name_{
    color: #ffff;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 600;
}
/* @keyframes circle {
    0% { transform: rotate(0deg); filter: hue-rotate(0deg);}
    100% {transform: rotate(30deg); filter: hue-rotate(360deg);}
} */

.product-image::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    background: #000;
     border-radius: 50%; 
    z-index: 1000;
}

.product-image::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(45deg, transparent, transparent 40%, #e5f403);
    background: #000;
    border-radius: 50%; 
    z-index: 1000;
    z-index: 1;
    filter: blur(30px); 
}

.product-image:hover {
    transform: translateY(-1.5rem) scale(1.03);
}

.view-products {
    color: red;
    font-size: 2.5rem;
    padding: 0 7rem 0 0;
    align-self: center;
    justify-self: end;
}

.item-image {
    width: 80%;
    border-radius: 5px;
}

hr {
    width: 100%;
    color: white;
}

.view-products i {
    color: #fefefa;
    font-size: 4rem;
}

.product-link {
    text-decoration: none;
}
@media only screen and (max-width: 1800px) {
 
    ._itemCard_{
        border: 1px solid #3d3d3d;
        width: 300px;
        text-align: center;
        /* height: 390px; */
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        background: unset;
        margin-bottom: 30px;
    }
    ._productBanner_ {
        height: 300px;
        width: 100%;
        background: #181818;
    }
    ._productsWrap_{
        display: flex;
        width: 100%;
        align-items: center;
        /* overflow: hidden; */
        justify-content: space-between;
    }
    .__productImage__ {
        width: 300px;
        height: 260px;
    }
}
@media only screen and (max-width: 1500px) {
 
    ._itemCard_{
        border: 1px solid #3d3d3d;
        width: 270px;
        text-align: center;
        /* height: 390px; */
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        background: unset;
        margin-bottom: 30px;
    }

    ._productBanner_ {
        height: 275px;
        width: 100%;
        background: #181818;
    }
    .__productImage__ {
        width: 270px;
        height: 140px;
    }
    ._bookwrapCards_{
        display: flex;
        justify-content: space-between;
    }
    ._productsWrap_{
        display: flex;
        width: 100%;
        align-items: center;
        /* overflow: hidden; */
        justify-content: space-between;
    }
    .item{
        width: 131px;
    margin-right: 35px;
    align-items: center;
    display: flex;
    flex-direction: column;
    }
    .__bookImage__{
        width: 270px;
        height: 450px;
    }
}
@media only screen and (max-width: 1050px) {
 
    ._itemCard_{
        border: 1px solid #3d3d3d;
        width: 180px;
        text-align: center;
        /* height: 390px; */
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        background: unset;
        margin-bottom: 30px;
    }
    ._productBanner_ {
        height: 250px;
        width: 100%;
        background: #181818;
    }
    ._productCover_{
        padding: 0px 50px 140px 50px;
    background: #181818;
    }
    .__productImage__ {
        width: 180px;
        height: 130px;
    }
    ._productsWrap_{
        display: flex;
        width: 100%;
        align-items: center;
        overflow: hidden;
        justify-content: space-between;
    }
    ._bookwrapCards_{
        display: flex;
        justify-content: space-between;
    }
    .__bookImage__{
        width: 180px;
        height: 450px;
    }
}
@media only screen and (max-width: 800px) {
    ._itemCard_ {
        border: unset;
        width: 77px;
        text-align: center;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        background: transparent;
        margin-bottom: 30px;
    }

    ._productBanner_ {
        height: 200px;
        width: 100%;
        background: #181818;
    }
    .__CardTitle__ {
        display: block;
        font-size: 14px;
        color: #b9b9b9;
        overflow: auto;
        align-items: center;
        text-overflow: ellipsis;
        font-family: sans-serif;
        line-height: 1.3;
        white-space: nowrap;
        width: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 30ch!important;
    }
    .Section2__Price {
        font-size: 12px;
        font-weight: 500;
        display: block;
        color: #ffffff;
    }
    ._productCover_{
        padding: 0px 25px 140px 25px;
    background: #181818;
    }
    ._priceSection_ {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px 0px 10px 0px;
        text-align: left;
         border:unset;
        background-color: transparent;
        width: 100%;
        justify-content: center;
    }
    .__bookImage__{
        width: 77px;
        height: 77px;
        object-fit: cover;
    }
    ._heading_{
        font-size: 20px;
      font-weight: 500;
      color: #fff;
      font-family: sans-serif;
    }
    ._productsWrap_{
        display: flex;
        width: 100%;
        align-items: center;
        /* overflow: hidden; */
        justify-content: space-between;
    }
    .__productImage__ {
        width: 77px;
        height: 77px;
    }

    ._productImageIcons_ {
        border-radius: 5px;
        width: 75px;
        height: 75px;
      }



    ._wrapCards_{
        margin-bottom: unset !important;
    }
}

@media (max-width: 992px) {
    ._name_{
        color: #ffff;
        font-size: 13px;
        font-family: sans-serif;
        font-weight: 600;
    }

    .scrollContainer {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 15px;
        overflow-x: auto;
        width: auto;
    }
    ._wrapCards_{
        margin-bottom: unset !important;
    }
}

 