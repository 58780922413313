.footer {
    height: 95px;
    display: flex;
    padding: 35px 0px 35px 0px;
    align-items: center;
    justify-content: center;
    background-color: #2a2a2a;

}

.footer-logo {
    position: relative;
    top: 4rem;
    left: 10rem;
    width: 100%
}

.footer-about {
    position: relative;
    /* align-self: center;
    justify-self: start;  */
    top: 4rem; 
    right: 25rem;
    font-size: 2rem;
    font-weight: 300;
}

.footer-copyright {
    position: relative;
    justify-self: center;
    font-size: 2rem;
    top: 2rem;
}

.footer-icon {
    justify-self: end;
    position: relative;
    right: 6rem;
    font-size: 3rem;
}

.footer-icon i {
    padding: 1rem;
    /* border-radius: 50%;
    border: 2px solid #fefefe; */
    box-sizing: border-box;
}

.i-fb {
    position: relative;
    top: 0.7rem;
    font-size: 5rem;
    color: #3f69ae;
}

.i-tw {
    background: #4269b3;
    color: white;
}

.i-lin {
    position: relative;
    top: 0.7rem;
    font-size: 5rem;
    color: #007cba;
}

.i-in {
    background: #8c3ab1;
    color: white;
}

h5 {
    font-size: 3rem;
    font-weight: 400;
}

li {
    list-style-type: none;
}
._footerInner_{
    background-color: #2a2a2a;
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: space-around;
}
 ._mblSocial_{
    display: none;
}
._footerLink_{
    text-decoration: none;
    color:#b9b9b9;
    font-family: sans-serif;
    font-size: 18px;
  font-weight: 300;
}
._footerLink_:hover{
    color:#ffffff;
}
._rightsSection_{
    height: 105px;
    padding: 40px 0px 40px 0px;
    color: #b9b9b9;
    display: flex;
    background: #181818;
    align-items: center;
    justify-content: center;
}
._rightsInner_{
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: flex-start;
}
._social_{
    margin-right: 20px;
}
._rightsInner_ p{
    font-size: 18px;
    font-weight: 300;
    font-family: sans-serif;
    margin: 0px;
    flex-grow: 1;
    display: block;
}

@media screen and (max-width: 900px) {
    .footer {
        height: 413px;
        display: flex;
        padding: 35px 0px 35px 0px;
        align-items: center;
        justify-content: center;
        background-color: #2a2a2a;
    
    }
    ._rightsSection_{
        height: 105px;
        padding: 40px 0px 40px 0px;
        color: #b9b9b9;
        display: flex;
        background: #181818;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    ._pcSocial_{
        display: none;
    }
    ._mblSocial_{
        display: block;
    }
    ._rightsInner_ p{
        font-size: 12px;
        font-weight: 300;
        font-family: sans-serif;
        margin: 0px;
        flex-grow: 1;
        display: block;
    }
    ._footerInner_{
        background-color: #2a2a2a;
        display: flex;
        align-items: center;
        height: inherit;
        flex-direction: column;
        width: 85%;
        justify-content: space-around;
    }
   }
