.Checkout {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #454545;
}

form {
    width: 50%;
    padding: 20px;
}

form h2 {
    width: 100%;
    color: #fefefa;
    font-size: 4rem;
    text-align: center;
    margin-bottom: 10px;
}

form h3 {
    width: 100%;
    color: #fefefa;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 10px;
}

.row100 {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.col {
    position: relative;
    width: 100%;
    padding: 0 10px;
    margin: 30px 0 10px;
    transition: 0.5s;
}

.col-1 {
    width: 40%;
    padding: 0 10px;
    margin: 30px 0 10px;
    transition: 0.5s;
    position: relative;
}

.col-2 {
    width: 40%;
    padding: 0 10px;
    margin: 30px 0 10px;
    transition: 0.5s;
    position: relative;
}

.input-box {
    position: relative; 
    width: 100%;
    height: 40px;
    color: #fefefa;
}

.input-box input {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    box-shadow: none;
    outline: none;
    font-size: 18px;
    padding: 0 10px;
    z-index: 1;
    color: #000;
}

.tex {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 40px;
    font-size: 18px;
    padding: 0 10px;
    display: block;
    transition: 0.5s;
    pointer-events: none;
}

input:focus + .tex, input:valid + .tex {
    left: -10px;
    top: -35px;
}

.line {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefa;
    transition: 0.5s;
    pointer-events: none;
}

input:focus ~ .line, input:valid ~ .line {
    height: 100%;
}

.payment-text {
    color: #fefefa;
    font-size: 3rem;
    position: relative;
    left: 0.8rem;
    margin-top: 4rem;
}

form button {
    border: none;
    padding: 7px 35px;
    cursor: pointer;
    outline: none;
    background: #ed2939;
    color: #fefefa;
    font-size: 18px;
    border-radius: 25px;
    text-transform: uppercase;
    margin-top: 1rem;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    form {
        width: 100%;
    }
}