h4{
    color: #fff;
    font-size:2.5rem;
}
.contact-text{
    text-align: center;
}
/* .contact-us-container{
    text-align: center;
} */
.contact-form{
    width: 50%;
    margin: auto;
    background: #fff;
    border-radius: 10px;
}
.header-content{
    background-color: #454545;
    padding: 2rem 4rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}
.header-content h4 , .header-content p{
    text-align: left;
}

.form-content{
    padding: 2rem 4rem;
}
.form-content div{
    display: flex;
    flex-direction: column;
    /* width: 70%; */
}
label{
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: .5rem;
}
label span{
    color: red;
}
input , textarea{
    height: 40px;
    border-radius: 5px;
    border: 1px solid #181818;
    padding: 0 10px;
    font-size: 1.4rem;
}
input:focus , input:active , textarea:focus , textarea:active{
    outline: 0;
}
textarea{
    min-height: 100px;
}

.submit-btn{
    width: 100px;
    height: 30px;
    border: 0;
    background-color: #454545;
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 5px;
    margin:4rem auto;
    transition: all .3s ease;

}
.submit-btn:hover{
    transform: scale(1.05);
}

@media screen and (max-width:800px) {
    .contact-form{
            width: 100%;
    }
}