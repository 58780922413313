.Section2__CardImage {
  /* border-radius: 10px; */
  width: inherit;
  height: 225px;
}
._priceSection_ {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px;
  text-align: left;
  border: 1px solid #3d3d3d;
  background-color: #2a2a2a;
  width: 100%;
  justify-content: flex-start;
}

.Section2__Cards {
  /* width: 60%;  */
  width: 1125px;
  /* height: 700
px
; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  float: left;
}

.Section2__CardMain {
  border: 1px solid #3d3d3d;
  width: 350px;
  /* height: 400px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin-bottom: 30px;
  cursor: pointer;
}

/* .Section2__CardMain p{
  font-size: 14px;
  line-height: 1.3;
} */
.Section2__Price {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
.Section2__CardTitle {
  font-size: 22px;
  color: #b9b9b9;
  font-family: sans-serif;
  line-height: 1.3;
  max-width: 30ch !important;
  white-space: nowrap;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding: 10px; */
}

.Section2__Main {
  display: flex;
  margin-bottom: 190px;
  /* align-items: center; */
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
}
._section2inner_ {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
._section2headingWrap_ {
  width: 1110px;
  display: flex;
  justify-content: flex-start;
}
.Section2__Heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 60px;
  font-family: sans-serif;
  padding-top: 40px;
  margin-bottom: 40px;
  position: relative;
  /* left: 30px; */
}
.Section2__ADpart {
  /* width: 30%; */
  height: inherit;
  float: right;
  margin-left: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  position: relative;
}
._section2Heading_{
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
.Section2__ADpartInner {
  position: absolute;
  top: 15px;
  border: 1px solid #fb5a48;
  width: 250px;
  height: 560px;
  float: right;
  background-color: #fb5a48;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: space-around;
}

.Section2__ADpartInner p,
.Section2__ADpartInner h3 {
  color: white;
}

.Section2__ADpartInner p {
  padding: 10px;
}
._more_ {
  cursor: pointer;
  font-size: 20px;
  font-family: sans-serif;
  color: #b9b9b9;
  text-decoration: underline;
}
.Section2__MainPart {
  width: 100vw;
  /* height: 850px; */
  padding-left: 140px;
  margin-top: 120px;
  padding-right: 140px;
  background-color: #181818;
  height: 100%;
}
._mblMore_{
    display: none;
    cursor: pointer;
    font-size: 20px;
    font-family: sans-serif;
    color: #b9b9b9;
    text-decoration: underline;
}
._more_{
    display: block;
    cursor: pointer;
    font-size: 20px;
    font-family: sans-serif;
    color: #b9b9b9;
    text-decoration: underline;
}
._sec2Cards_{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}
h3 b {
  color: #fefefa;
  font-size: 2.4rem;
}

.pName {
  /* border: 1px solid red; */
    width:200px;
    line-height: 1.5em;
    height: 1.4em;
    overflow: hidden;
    font-size: 18px;
}

.price-pad {
  padding-right: 10px;
  color: #6e6d6d;
  text-decoration: line-through;
}

.pName::after {
  content: ' ...';
}

@media only screen and (max-width: 1900px) {
    ._sec2Cards_{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
    }
    ._mblMore_{
        display: none;
        cursor: pointer;
        font-size: 20px;
        font-family: sans-serif;
        color: #b9b9b9;
        text-decoration: underline;
    }
    ._more_{
        display: block;
        cursor: pointer;
        font-size: 20px;
        font-family: sans-serif;
        color: #b9b9b9;
        text-decoration: underline;
    }
  .Section2__CardMain {
    border: 1px solid #3d3d3d;
    width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background: #fefefa;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .Section2__ADpart img {
    width: 375px;
    height: 730px;
  }
  .Section2__CardImage {
    width: inherit;
    height: 210px;
  }
}
@media only screen and (max-width: 1600px) {
    ._sec2Cards_{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
    }
    ._mblMore_{
        display: none;
        cursor: pointer;
        font-size: 20px;
        font-family: sans-serif;
        color: #b9b9b9;
        text-decoration: underline;
    }
    ._more_{
        display: block;
        cursor: pointer;
        font-size: 20px;
        font-family: sans-serif;
        color: #b9b9b9;
        text-decoration: underline;
    }
  .Section2__CardMain {
    border: 1px solid #3d3d3d;
    width: 300px;
    /* height: 400px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .Section2__MainPart {
    width: 100vw;
    /* height: 850px; */
    padding-left: 50px;
    margin-top: 120px;
    padding-right: 50px;
    background-color: #181818;
    height: 100%;
  }
  .Section2__ADpart img {
    width: 375px;
    height: 730px;
  }
  .Section2__CardImage {
    /* width: inherit; */
    /* width: 70%;
    height: 156px; */
    width: 298px;
    height: 300px;
  }
}
@media only screen and (max-width: 1200px) {
    ._sec2Cards_{
        display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    }
    ._mblMore_{
        display: block;
        cursor: pointer;
        font-size: 20px;
        font-family: sans-serif;
        color: #b9b9b9;
        text-decoration: underline;
    }
    ._more_{
        display: none;
        cursor: pointer;
        font-size: 20px;
        font-family: sans-serif;
        color: #b9b9b9;
        text-decoration: underline;
    }
    ._section2inner_ {
        width: 100%;
        overflow: auto;
        flex-direction: column;
        display: flex;
        justify-content: space-evenly;
    }
    .Section2__Cards {
        /* width: 60%; */
        overflow: auto;
        width: unset ;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        float: left;
    }
    .Section2__CardMain {
        border: 1px solid #3d3d3d;
        width: 250px;
        height: unset;
        text-align: center;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        background: transparent;
        margin-bottom: 30px;
        cursor: pointer;
    }
    .Section2__ADpart{
display: none;
    }
}

@media only screen and (max-width: 750px) {
  ._sec2Cards_{
      display: flex;
      flex-wrap: nowrap;
  }
  ._mblMore_{
      display: block;
      cursor: pointer;
      font-size: 20px;
      font-family: sans-serif;
      color: #b9b9b9;
      text-decoration: underline;
  }
  ._more_{
      display: none;
  }
  ._section2Heading_{
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
  }
  .Section2__MainPart {
    width: 100vw;
    /* height: 850px; */
    padding-left: 25px;
    margin-top: 120px;
    padding-right: 25px;
    background-color: #181818;
    height: 100%;
  }
  ._section2headingWrap_{
      display: none;
      width: unset;
  }
  .Section2__Main {
    display: flex;
    margin-bottom: 100px;
    /* align-items: center; */
    width: 100%;
    justify-content: space-around;
    flex-direction: row;
}
._section2headingWrap_ {
    /* width: 1110
px
; */
    display: flex;
    justify-content: flex-start;
}
._section2inner_ {
    width: 100%;
    overflow: auto;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
}
.Section2__Cards {
    width: 60%;
    overflow: auto;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    float: left;
}
.Section2__Heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 60px;
    font-family: sans-serif;
    padding-top: 40px;
    margin-bottom: 40px;
    position: relative;
    /* left: 30
px
; */
}

.Section2__CardMain {
    border: 1px
 solid #3d3d3d;
    width: 250px;
    margin-right: 25px;
    height: unset;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin-bottom: 30px;
    cursor: pointer;
}
}