.MyOrders {
    background: #454545;
    color: #fefefa;
    height: calc(100vh - 374px);
    overflow-y: auto;
    min-height: 600px;
}

.MyOrders h3 {
    font-size: 3.2rem;
    color: #b9b9b9;
}

.MyOrders .my-orders {
    padding-left: 193px;
    padding-top: 3rem;
}

.MyOrders .my-orders .my-order{
    font-size: 1.8rem;
    padding-top: 3rem;
    padding-left: 100px;
    border-bottom: 1px solid #3d3d3d;
}

.MyOrders .my-orders .my-order div{
    padding-top: 1.5rem;
}

.MyOrders .my-orders .product-orders {
    color: #b9b9b9;
}

.MyOrders .my-orders .invoice-amount {
    padding-bottom: 24px;
    padding-top: 24px !important;
}

.MyOrders .my-orders .my-order h4{
    font-size: 1.8rem;
    color: #b9b9b9;
}

.MyOrders .my-orders .my-order h4 b{
    color: #fff;
}

.pad{
    padding-left: 5rem;
}  

.my-order:last-child {
    border-bottom: none !important;
}

.order-empty-list {
    color: #b9b9b9;
    font-size: 20px;
    margin: 50px 250px;
}  

@media screen and (max-width: 780px) {
    .MyOrders {
        height: 100%;
    }
    
    .MyOrders .my-orders {
        padding-left: 27px;
        padding-top: 3rem;
    }

    .MyOrders .my-orders .my-order  {
        padding-left: 50px;
    }
}