.ResetPassword {
    height: 100vh;
    display: grid;
    justify-items: center;
}

.ResetPassword form .reset-password-input {
    border: 1px solid #b8b8b8;
    height: 4rem;
    width: 100%;
    font-size: 1.6rem;
    padding-left: 2rem;
    border-radius: 3px;
}

.ResetPassword .err-msg {
    color: #ed2939;
    font-size: 14px;
    text-align: center;
}

.ResetPassword h3 {
    font-size: 3.2rem;
    margin-top: 2rem;
}

.ResetPassword form {
    width: 50%;
    padding: 100px;
}

.ResetPassword form .reset-password-button {
    border: none;
    padding: 7px 35px;
    cursor: pointer;
    outline: none;
    background: #454545;
    color: #fefefa;
    font-size: 18px;
    border-radius: 25px;
    text-transform: uppercase;
    width: 60%;
    justify-self: center;
    align-self: center;
    margin: 30px 100px;
}

.ResetPassword form h3 {
    color: #454545;
    font-size: 3.2rem;
}

.ResetPassword label {
    font-size: 1.8rem;
    position: relative;
    padding-top: 1rem;
    color: #b8b8b8;
}

.ResetPassword form i {
    position: absolute;
    font-size: 2rem;
    color: #b8b8b8;
    right: 10px;
    margin-top: 7px;
}

.ResetPassword form div {
    width: 100%;
    position: relative;
    top: 1rem;
    color: #b8b8b8;
    font-size: 1.5rem;
}

@media only screen and (max-width: 780px) {
    .ResetPassword form {
        width: 100%;
        padding: 14px;
    }
}