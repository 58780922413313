.Header__OuterDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  padding:24px 0px 24px 0px;
  background-color: #181818;
  height: 104px;
}

.account-menu {
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
}

.dropdown-item {
  padding: 1rem 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.Header__OuterImage {
  /* height: 40px;
  width: 180px; */
  background-attachment: fixed;
  background-size: cover;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.Header__InnerDiv {
  display: flex;
  height: 30px;
  width: 112px;
  justify-content: space-evenly;
  align-items: center;
}
._Icons_{
  width:25px;
  height:25px;
  color:white;
}
.Header__IconOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-right: 45px;
  font-size: 16px;
  /* border: 1px solid black; */
  border-radius: 50%;
  color: black;
  position: relative;
}

.Header__Search {
  border: none;
  background:transparent;
  outline: none;
  height: 35px;
  margin-left: 25px;
  color: #848484;
  font-size: 16px;
  font-family: sans-serif;
  /* width: 400px; */
  border-radius: 25px;
  font-size: 1.5rem;
  /* color: gray; */
}
::placeholder {
  color: gray;
}
.Header__Searchout {
  display: flex;
  position: relative;
  justify-content: start;
  align-items: center;
  flex-direction: row-reverse;
  flex-wrap:wrap;
  height: 54px;
  background-color: #2a2a2a;
  /* width: 27%; */
  flex-grow:0.2;
  border: 1px solid #3d3d3d;
  border-radius: 28px;
  /* -moz-box-shadow:   2px 2px 4px 5px #ccc;
   -webkit-box-shadow: 2px 2px 4px 5px #ccc; */
  /* box-shadow: 1px 1px 3px 4px #ccc; */
}

.Header__Search__Wrapper {
  position: relative;
  width: 400px;
}

.search-dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 2002;
  background: #2a2a2a;
  box-shadow: 0 2px 4px 0 rgb(18 52 77 / 6%), 0 2px 18px 0 rgb(18 52 77 / 16%);
  border: 1px solid #3d3d3d;
  overflow: auto;
  padding: 8px 0;
  transform: translateZ(0);
  left: 1px !important;
  opacity: 1;
  border-top: 0;
  max-height: 500px;
  width: 100%;
  border-radius: 4px;
  text-align: left;
}

.search-dropdown-item {
    padding: 1rem 20px;
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: #848484;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #3d3d3d;
}

.search-dropdown-item:last-child {
  border-bottom: none !important;
}

.Header__SectionOuter {
  display: flex;
  width: 100vw;
  justify-content: space-around;
  align-items: center;
  height: 70px; 
  background-color: #2a2a2a;
}
._navWrap_{
  display: flex;
  align-items: baseline;
  width: 95%;
  margin-left: 140px;
    margin-right: 140px;
}
._Header-inner_{
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 140px;
  margin-right: 140px;
}
.nav-link {
  font-size: 16px;
  text-decoration: none;
  display: flex;
  font-family: sans-serif;
  justify-content: center;
  align-items: center;
  flex-grow: 0.7;
  /* width: 230px; */
  /* border-right: 1px solid black; */
  text-align: center;
  cursor: pointer;
}

.nav-links:hover {
  color: #ed2939;
}

.nav-links:hover:after {
  width: 100%;
  bottom: 40px;
  background: #ed2939;
}

.active {
  color: #ed2939;
  border-bottom: 3px solid #ed2939;
}
/* 
.Header__SectionOuter div:nth-child(1) {
  height: 50px;
  width: 100px;
} */

/* .Header__SectionOuter div:nth-child(7) {
  height: 50px;
  width: 100px;
  border: 0;
} */
.Header__Chat {
  color: red;
  padding: 10px;
}

.input-search-ic {
  position: relative;
  color:white;
  /* left: 5rem; */
  margin-left: 25px;
  font-size: 2rem;
}

.dropdown-toggle::after {
  border: none;
}

.log-nav {
  background: #2a2a2a;
  /* color: #fefefa; */
}

#drop-down {
  background: none;
}

.log-nav li p {
  color: #fefefa;
  margin-bottom: 0;
  font-weight: 10;
}

.profile-dropdown {
  width: 25rem;
  height: 15rem;
  position: relative;
  top: 8rem;
  /* letter-spacing: 0.3rem; */
}

.profile-dropdown li {
  font-size: 1.2rem;
}

.nav-orders {
  color: black;
  text-decoration: none;
}

.dropdown-item:hover,
.nav-orders:hover {
  color: #ed2939;
}
._headerChat_{
margin-left: 5px;
}
.popup-content {
  /* left: 850px !important; */
  width: 500px !important;
  margin: auto;
  background:#2a2a2a;
  /* width: 50%; */
  padding: 5px;
  border: 1px solid #d7d7d7;
}

.nav-link-boder {
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  text-align: center;
  cursor: pointer;
}

ul li p {
  font-size: 12px;
}

ul li p i {
  padding-right: 5px;
}

.cart-items-count {
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  width: 14px;
  height: 14px;
  text-align: center;
  border-radius: 50%;
  left: 10px;
  bottom: 20px;
  background-color: #333e48;
  color: #fff;
}

.nav-user-icon {
  color: #fefefa;
}