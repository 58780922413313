.orderHisContainer {
    padding: 50px 140px 140px;
    background: #181818;
    display: block;

}

h2 {
    font-size: 34px;
    margin-bottom: 30px;
    color: #ffff;
    font-family: sans-serif;
    font-weight: 500;
}

.orderHisTable {
    width: 100%;
    background-color: #2a2a2a;
    border-radius: 4px;
    border: 1px solid #ffffff20;
    margin-bottom:20px;

}

.rowOne {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
}

.rowOne, .rowTwo {
    padding: 10px 30px;
    border-bottom: 1px solid #ffffff20;

}

p {
    color: #b9b9b9;
    font-size: 16px;
    margin: 0;
}

p span {
    color: #ffffff;
}

.center {
    display: flex;
    align-items: center;

}

.t-right {
    text-align: right;
}

.invoiceBtn {
    width: 200px;
    border-radius: 50px;
    height: 50px;
    background-color: red;
    color: #ffffff;
    text-align: center;
    border: 0;
    font-size: 16px;
}

.header, .tableContent {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    place-items: center;
}

.ps {
    place-self: baseline;
}

.header p {
    text-transform: uppercase;
}

.tableContent {
    padding: 10px 0;
}

.tableContent p {
    color: #ffffff;
}

.productContainer {
    display: flex;
    align-items: center;
}

.imgContainer {
    width: 80px;
    height: 80px;
    border-radius: 3px;
    background-color: #fff;
    margin-right: 15px;
}

.imgContainer img {
    width: 100%;
    height: 100%;

}

.pr-70 {
    padding-right: 70px;
}

/* for mobile */
.mobileOrderHisContainer {
    display: none;
    background: #181818;
}

.mobTitHeader {
    margin-bottom: 20px;
    box-shadow: 3px 8px 20px #00000080;
    padding: 20px 0;
    position: relative;
}

.backBtn {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 20px;
    object-fit: scale-down;
    cursor: pointer;
}

.mobMainContainer {
    padding: 20px;
}

.mobRowTwo {
    margin-bottom: 50px;
    width: 100%;
    background-color: #2a2a2a;
    border-radius: 4px;
    border: 1px solid #ffffff20;
    margin-top: 15px;
}

.mobProductContainer {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff20;

}

.mobImgContainer {
    width: 80px;
    height: 80px;
    border-radius: 3px;
    background-color: #fff;
    margin-right: 15px;
}

.mobImgContainer img {
    width: 100%;
    height: 100%;

}

.mobileOrderHisContainer h2 {
    text-align: center;
    font-size: 25px;
    margin: 0;
}


.mobRowEnd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 10px;
}

.mobDownBtn {
    width: 30px;
    height: 30px;
    object-fit: scale-down;
}

@media only screen and (max-width:800px) {
    .orderHisContainer {
        display: none;
    }

    .mobileOrderHisContainer {
        display: block;
    }
}