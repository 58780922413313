.Login {
    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr); */
    overflow-x: hidden;
    overflow-y: hidden;
    height: 700px;
    /* justify-items: center; */
}

.Login .left {
    /* position: relative; */
    /* left: 5rem; */
    /* top: 5rem; */
    margin: auto;
    width: 50%;
}

.Login .left h2{
    position: relative;
    top: 3rem;
    font-size: 3rem;
    font-weight: 600;
    color: rgb(85, 82, 82);
}
.Login .left h4 {
    position: relative;
    top: 18rem;
    left: 8rem;
}

.Login .left p{
    position: relative;
    top: 3rem;
    font-size: 1.5rem;
    color: #b8b8b8;
}

.Login .left form {
    position: relative;
    display: grid;
    top: 3rem;
    width: 80%;
    align-items: center;
}

.Login .left label {
    width: 100%;
}

.Login .login-or {
    position: relative;
    top: 10rem;
    /* left: 18rem; */
    text-align: center;
    right: 5rem;
    width: 2rem;
    background: #fefefa;
    left: 30rem;
}

.Login .left label {
    font-size: 1.8rem;
    position: relative;
    padding-top: 1rem;
    color: #b8b8b8;
}

.Login .left form {
    padding: 0 0 0 2.3rem;
}

.Login .left form .login-input {
    border: 1px solid #b8b8b8;
    height: 4rem;
    width: 100%;
    font-size: 1.6rem;
    padding-left: 2rem;
    border-radius: 3px;
}

.Login .left form i {
    position: absolute;
    bottom: 2.8rem;
    font-size: 2rem;
    color: #b8b8b8;
    right: 0;
    top: 9px;
    right: 12px;
}

.Login .left form div {
    width: 100%;
    position: relative;
    top: 1rem;
    color: #b8b8b8;
    font-size: 1.5rem;
}

.Login .left form div .remember {
    position: relative;
    left: 0.5rem;
}

.Login .left form div .forget-password {
    float: right;
}

.Login .left form .login-form{
    border: none;
    padding: 7px 35px;
    cursor: pointer;
    outline: none;
    background: #454545;
    color: #fefefa;
    font-size: 18px;
    border-radius: 25px;
    text-transform: uppercase;
    margin-top: 7rem;
    width: 60%;
    justify-self: center;
    align-self: center;
}

.Login hr {
    color: #fddede;
    position: relative;
    top: 7.5rem;
    width: 60rem;
}

.Login .left h3 {
    position: relative;
    top: 15rem;
    left: 19rem;
}

.Login .left h3 span{
    color: #b8b8b8;
}

.Login .left .red {
    color: #ed2939;
}

.Login .left .green {
    color: #0bc215;
}

.Login .left a {
    text-decoration: none;
    color: black;
}

.Login .right {
    display: grid;
    grid-template-columns: 1fr;
    background: #454545;
    background-size: auto 1600px;
    /* height: 100vh; */
    align-items: center;
    justify-items: center;
}

.Login .right img{
    width: 40%;
    position: relative;
    top: 3rem;
}

.Login .login-fb {
    width: 30%;
}

.Login .login-google {
    width: 30%;
}

@media only screen and (max-width: 600px) {
  
    .Login .left {
        width: 100%;
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        height: 70rem;
        width: 100%    ;
    }
    .Login .left h3 {
        position: relative;
        top: 8rem;
        left: 2rem;
    }
    .Login .login-fb {
        width: 35%;
    } 
    .Login .login-google {
        width: 35%;
    } 
 
    .Login .left form {
        position: relative;
        display: grid;
        top: 3rem;
        width: 70%;
        align-items: center;
    }
    .Login .login-or {
        left: 2rem;
        top: 6rem;
    }
    .Login .left form .login-form{
        left: 11rem;
        width: 18rem;
        height: 4rem;
        padding: 5px 5px;
        font-size: 1.4rem;
    }
    /* .Login .left h4 {
        position: relative;
        top: 18rem;
        left: 2rem;
    } */
    .Login .right {
        height: 60rem;
    }
    .Login .left h4 {
        position: relative;
        top: 8rem;
        left: 2rem;
    }   
}

