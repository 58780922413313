.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 5rem;
  /* height: 20rem; */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.d-block{
  width: 100%;
  height:420px;
}
.Section__Carousel {
  /* height: 500px; */
  float: left;
  width: 70%;
  flex-wrap: wrap;
}

.Section1__Main {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* height: 500px; */
}

.Section1__Second {
  /* height: 500px; */
  float: right;
  width: 30%;
  flex-wrap: wrap;

}

.carousel-indicators {
  border-radius: 50% !important;
}

.carousel-caption h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.carousel-caption p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 780px) {
.Section__Carousel {
  width: 0px;
}
.Section1__Second {
  width: 80%;
}
}
