.static-container{
    background-color: #181818;
    padding: 3rem 10%;
}
.static-container h3{
    color: #fefefa;
    font-size: 3.2rem;
    margin-bottom: 60px;
    text-align: center;
}
p , ul li , ul{
    font-size: 2rem;
    color: #d8d8d2;
    margin: 1rem 0;
}
ul li::before {
    content: "\2022";
    color: #fff;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
.sub-header{
    text-decoration: underline;
}
.disclaimer{
    border: 1px solid white;
    background-color: #181818;
    padding: 15px;
    margin: 2rem 0;
}
h1{
    color: #fff;
}