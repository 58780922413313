.faq-container{
    padding: 5rem 10%;
    background-color: #181818;
}
.faq-container h3{
    color: #fefefa;
    font-size: 3.2rem;
    margin-bottom: 60px;
}
.accordion-item, .accordion-button , .accordion-button:not(.collapsed){
    background-color: #181818;
}
.accordion-button , .accordion-collapse , .accordion-button:not(.collapsed){
    border: 1px solid rgba(255, 255, 255, 0.079);
    color: #fff;
}
.accordion-button{
    font-size: 2rem;
}
.accordion-body{
    font-size: 1.5rem;
}
.accordion-button:not(.collapsed)::after , .accordion-button::after{
    filter: brightness(0) invert(1);
}