.Community h3 {
  color: #fefefa;
  font-size: 3.2rem;
  position: relative;
  top: 5rem;
  left: 3rem;
  margin-bottom: 60px;  
}
.Community{
  background: #181818;
  font-family: sans-serif;
  padding: 0 10%;
}
.Community .community-section-1 {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  align-items: center;
  justify-items: center;
}

.Community .community-section-2divider {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  text-align: center;
  margin:3rem 0;

  
}
.Community .community-section-3divider {
  grid-template-columns: repeat(3, 1fr);
  margin:3rem 0;

}
.bor-r{
  border-right: 1px solid #fff;
}

.Community .community-section-1 .content-1 {
  text-align: center;
  padding-left: 3rem;
  padding-bottom: 30px;
}
.Community .community-content p {
  font-size: 2rem;
  color: #d8d8d2;
  text-align: center;
}

.Community .community-section-2 {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 3rem;
  padding-right: 3rem;
}

.Community .community-section-2 .content-2 {
  text-align: center;
  padding-bottom: 2rem;
  padding-bottom: 30px;
}

.Community .stores {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 5rem 0;
}

.Community .stores .apple-store {
  justify-self: flex-end;
  width: 24rem;
  padding: 0 10px 20px 0;
}
.Community .stores .google-store {
  justify-self: flex-start;
  width: 24rem;
  padding: 0 0 20px 10px;
}

@media screen and (max-width: 780px) {
    .Community {
        text-align: center;
    }
    .Community h3{
        left: 0;
    }
  .Community .community-section-1 {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    padding-top: 5rem;
  }
  .Community .community-section-1 .content-1 {
    text-align: justify;
    grid-column: 1/1;
    grid-row: 2;
    padding: 0 2rem 2rem 2rem;
  }
  /* .Community .community-section-1 .community-image {
    grid-column: 1/1;
    grid-row: 1;
    width: 100%;
  } */
  .Community .stores {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 5rem;
  }

  .Community .stores .apple-store {
    justify-self: center;
    align-self: center;
    padding: 0 0 10px 0;
  }

  .Community .stores .google-store {
    justify-self: center;
    align-self: center;
    padding: 0 0 20px 0;
  }
  .sub-title{
    height: 30px;
  }
  .Community .community-section-2divider{
    grid-template-columns: 1fr;
  }
  .bor-r{
    border-right: 0;
    border-bottom: 1px solid #fff;
    margin-bottom: 2rem;
  }
}
