.navbar {
  background-color: #181818;
  /* height: 80px;  */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  padding: 17px 25px 17px 25px;
  top: 0;
  z-index: 20;
  /* padding-bottom: 12px; */
}

.pos-rel {
  position: relative;
}

._hamburger_{
  color:#fff;
  font-size: 24px;
  width: 24px;
  display: flex;
  height: 24px;
}
.nav-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  max-width: 1500px;
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  font-family: sans-serif;
  margin-right: 2rem;
}

.nav-links {
  color: #b9b9b9;
  text-decoration: none;
  font-size: 18px;
  padding: 1.9rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  /* line-height: 40px; */
  margin-right: 1rem;
  text-decoration: none;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ed2939;
}

.nav-item .active {
  color: #ed2939;
  border: 1px solid #ed2939;
}

.nav-icon {
  display: none;
}

.header__search {
  border: none;
  outline: none;
  height: 35px;
  width: 200px;
  border-radius: 25px;
}
.header-searchout {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 35px;
  border: 1px solid white;
  -moz-box-shadow: 2px 2px 4px 5px #ccc;
  -webkit-box-shadow: 2px 2px 4px 5px #ccc;
  box-shadow: 1px 2px 1px 1px #ccc;
}

.search-icon {
  /* margin-right: 10%; */
  position: relative;
  bottom: 23px;
  /* left: 247px; */
}

::placeholder {
  color: red;
  padding-left: 5px;
}

._social_{
  margin-right: 20px;
}

.nav-user-icon {
  color: #fefefa;
  font-size: 24px;
  margin-left: 24px;
  position: relative;
}

@media screen and (max-width: 960px) {
  .nav-logo {
    /* max-width: 100px; */
    float: left;
    margin: 0;
    position: relative;
    left: 2rem;
  }
  .nav-logo-image {
    width: 160px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #181818;
    left: 0px;
    opacity: 1;
    color: #fff;
    font-family: sans-serif;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #ed2939;
    border: none;
    text-decoration: underline;
  }
  .nav-links {
    width: 100%;
    display: table;
    text-align: end;
    text-decoration: none;
    font-size: 2rem;
  }

  .nav-links:hover {
    color: #ed2939;
  }

  .nav-links:hover:after {
    width: 100%;
    bottom: 40px;
    background: #ed2939;
  }

  .Header__IconOuter {
    margin-right: 36px !important;
  }

.mblHeader__IconOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 0;
  margin-right: 30px;
  font-size: 16px;
  /* border: 1px solid black; */
  border-radius: 50%;
  color: black;
  position: relative;
}
  .nav-icon {
    display: block;
    position: absolute;
    top: 1.4rem;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 2.5rem;
    cursor: pointer;
    color: #1f1f1f;
  }
  .header__chat {
    font-size: 1rem;
    color: red;
  }
  .Header__Search {
    width: 180px !important;
  }
  .header-searchout {
    /* position: relative;
    left: -4rem; */
    width: 65%;
  }
  .Header__InnerDiv {
    position: relative;
    left: 1.5rem;
  }

  .profile-dropdown {
    min-width: 20rem;
    position: absolute !important;
    height: unset !important;
    top: 8rem !important;
    left: unset !important;
    /* letter-spacing: 0.3rem; */
  }

  .profile-title {
    padding-left: 4px;
  }

  .dropdown-item {
    border-bottom: 1px solid #faecec;
  }

  .Header__InnerDiv {
    display: flex;
    height: 30px;
    width: 27%;
    justify-content: space-evenly;
    align-items: center;
  }
  .header-input {
    width: 270px;
  }
  .header-input div input {
    border: 1px solid grey;
    border-radius: 25px;
    width: 270px !important;
    margin-top: 14px;
  }
  ul li h3 {
    font-size: 10px;
  }
}

.cart-items-count {
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  width: 14px;
  height: 14px;
  text-align: center;
  border-radius: 50%;
  left: 10px;
  bottom: 20px;
  background-color: #333e48;
  color: #fff;
}
