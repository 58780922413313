._FavoriteProducts_ {
    background: #2a2a2a;
    /* min-height: 100vh; */
    margin: 36px;
}

.product-link{
  text-decoration: none;
}

._FavoriteProducts_ h4 {
    /* text-align: center; */
    display: block;
    color: #fff;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
    font-family: sans-serif;
}
._mblfavQty_{

        width: 30%;
        align-items: center;
        display: flex;
        justify-content: center;
  
}
.Favorite-Products .favorite-map{
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    row-gap: 2rem;
    align-items: center;
    padding-bottom: 1rem;
}

.Favorite-Products img {
   min-width: 15rem !important;
   grid-row: 1;
}

.Favorite-Products h3 {
    font-size: 2.4rem;
    color: #fefefa;
    grid-row: 1;
    align-self: start;
    justify-self: start;
    justify-content: space-around;
    padding-bottom: 2rem;
}

.Favorite-Products button {
    border: none;
    width: 16rem;
    height: 3.4rem;
    border-radius: 2.5rem;
    background: #ed2939;
    color: #fefefa;
    font-size: 1.4rem;
}


    ._favProduct_ {
      display: flex;
      margin-bottom: 15px;
      height: 107px;
      background: #2a2a2a;
      cursor: pointer;
    }
    ._favPrice_ {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      font-family: sans-serif;
    }
    ._favQtyNumber_{
        margin:0px 15px 0px 15px;
        color: #fff;
        font-size: 12px;
        font-family: sans-serif;
    }
    ._favqtyControl_{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    ._favQty_{
      width: 50%;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    ._favproductName_ {
      color: #b9b9b9;
      font-family: sans-serif;
      font-size: 12px;
      margin-bottom: 7px;
    }
    ._favPriceSection_ {
      margin-left: 12px;
    }
    ._favProductImage_ {
      width: 87px;
      height: 87px;
    }
    ._favCart_ {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 75%;
    }
    ._favInner_{
        margin-bottom: 30px;
    }
    ._favWrap_ {
      padding: 25px;
      display: block;
      min-height: 900px;
      background: #181818;
    }
  


@media only screen and (max-width: 600px) {
    .Favorite-Products div {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 0rem;
        position: relative;
        left: 2rem;
    }
    .Favorite-Products img {
        min-width: 15rem !important;
    }

    ._mblInner_ h4 {
      /* text-align: center; */
      display: block;
      color: #fff;
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 500;
      font-family: sans-serif;
  }
}