.cookies-table{
    margin: 2rem 0;
}
.row-one{
    display: grid;
    grid-template-columns: 2fr 5fr;
    
}
.row-content{
    border: 1px solid #fff;
    padding: 10px;
}
.br-0{
    border-right: 0;
}