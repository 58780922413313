/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
1800px + :      Big desktop

1em = 16px
*/
  
*,
*::after,
*::before {
margin: 0;
padding: 0;
box-sizing: inherit; }
  
html {
    font-size: 62.5%; 
}

@media only screen and (max-width: 75em) {
    html {
        font-size: 56.25%; 
    } 
}

@media only screen and (max-width: 56.25em) {
    html {
        font-size: 50%; 
    } 
}
@media only screen and (min-width: 112.5em) {
    html {
        font-size: 75%; 
    } 
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cp{
    cursor:pointer !important
}
