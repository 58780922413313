.heading-primary {
    color: #fefefa;
    text-transform: uppercase;
    backface-visibility: hidden;
    font-size: 3rem;
    align-self: center;
    justify-self: start;
}

@media only screen and (max-width: 37.5em) {
.heading-primary {
    letter-spacing: 1rem;
    font-size: 1.5rem; 
    } 
}
  
.heading-secondary {
    font-size: 3.5rem;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: .2rem;
    color: #fefefa;
    align-self: center;
    justify-self: start;
}

@media only screen and (max-width: 56.25em) {
    .heading-secondary {
        font-size: 3rem; 
    } 
}
@media only screen and (max-width: 37.5em) {
    .heading-secondary {
        font-size: 2.5rem; 
    } 
}

.products-name-main {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: .2rem;
    color: #fefefa;
}

@media only screen and (max-width: 37.5em) {
    .products-name-main {
        font-size: 1rem; 
    } 
}

.products-sub {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .2rem;
    color: #fefefa;
}

@media only screen and (max-width: 37.5em) {
    .products-sub {
        font-size: 1rem; 
    } 
}