.blog {
    overflow-x: hidden;
    background: #181818;
    font-family: sans-serif;
    padding: 0 10%;
    height: calc(100vh - 374px);
}
.blogs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;
  position: relative;
  top: 1rem;
  /* align-items: center; */
  justify-items: center;
}
.blogs-content {
  padding: 4rem;
}
.blog h3 {
  font-size: 3.2rem;
  color: #fefefa;
  position: relative;
  top: 6rem;
  left: 3.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
}
.blogs-content p {
  font-size: 2rem;
  color: #d8d8d2;
}

.blogs-images {
  position: relative;
  left: 10rem;
}

.blogs-images .blog-img {
  width: 34rem;
  height: 275px;
}

@media screen and (max-width: 780px) {
  .blog {
    text-align: center;
    height: 100%;
  }
  .blog h3 {
      left: 0rem;
  }
  .blogs {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 90px;
    justify-items: center;
  }
  .blogs-images {
    grid-column: 1/1;
    grid-row: 1;
    left: 0;
  }
  .blogs-content {
    grid-column: 1/1;
    grid-row: 2;
  }
  .blogs-images .icon {
    position: relative;
    right: 0rem;
    top: -9rem;
  }
}
